<template>
    <el-dialog
        title="选择关联信息"
        :visible.sync="isShow"
        width="480px"
        :before-close="onClose"
        :destroy-on-close="true"
        :append-to-body="true"
        @opened="onOpened"
    >
        <el-form ref="formData" v-if="formData" :model="formData" :rules="rules" label-width="80px">
            <el-form-item label="类型" prop="type">
                <el-select @change="onTypeChange" v-model="formData.type" placeholder="请选择">
                    <el-option v-for="item in productDetailType" :key="item.val" :label="item.name" :value="item.val" />
                </el-select>
            </el-form-item>
            <el-form-item label="详情" prop="targetId" v-if="formData.type === 'COURSE_PACKAGE'">
                <el-select
                    v-model="formData.targetId"
                    filterable
                    remote
                    placeholder="请输入课程包名称进行搜索"
                    :remote-method="getCoursePackageListApi"
                    :loading="loading"
                    clearable
                    @clear="getCoursePackageListApi"
                >
                    <el-option-group label="默认显示20条">
                        <el-option v-for="item in packageList" :key="item.id" :value="item.id" :label="item.title">
                            <Tag :color="packageTypeMap[item.ptype]['color']">
                                {{ packageTypeMap[item.ptype]['name'] }}
                            </Tag>
                            <span style="margin-left: 10px">{{ item.title }}</span>
                        </el-option>
                    </el-option-group>
                </el-select>
            </el-form-item>
        </el-form>
        <span slot="footer" class="dialog-footer">
            <el-button @click="onClose">取 消</el-button>
            <el-button type="primary" @click="onSubmit">确 定</el-button>
        </span>
    </el-dialog>
</template>
<script>
import { getCoursePackageList } from '@/js/api/courseApi.js'
import { addProductDetail } from '@/js/api/shopApi.js'
import { packageTypeList, packageTypeMap } from '@/js/config/course.js'
import { productDetailType } from '@/js/config/shop.js'

export default {
    props: ['isShow', 'activeProduct'],
    data() {
        return {
            packageTypeMap,
            productDetailType,
            formData: null,

            packageId: null,
            rules: {
                type: [{ required: true, message: '请选择', trigger: 'blur' }],
                targetId: [{ required: true, message: '请选择', trigger: 'blur' }],
            },
            loading: false,
            packageList: [],
        }
    },
    methods: {
        onOpened() {
            let formData = {
                productId: this.activeProduct.id,
                type: '',
                targetId: '',
            }
            this.formData = formData
            this.getCoursePackageListApi('')
        },
        onClose() {
            this.$emit('onClose')

            this.productList = []

            this.coursePackageList = []
        },
        onTypeChange() {
            this.formData.targetId = ''
        },
        onSubmit() {
            this.$refs.formData.validate((valid) => {
                if (valid) {
                    addProductDetail(this.formData).then(() => {
                        this.$emit('onClose', true)
                        this.$message.success('添加成功')
                    })
                }
            })
        },
        getCoursePackageListApi(query) {
            const params = {
                page: 1,
                orderBy: '-id',
                size: 20,
                showPage: false,
                ptypes: packageTypeList.map((i) => i.val),
            }
            if (query) params.title = query
            this.loading = true
            getCoursePackageList(params).then((res) => {
                this.loading = false
                this.packageList = res.data
            })
        },
    },
}
</script>
<style lang="less" scoped>
.el-select {
    width: 100%;
}
</style>
