export const productDetailTypeMap = {
    COURSE_PACKAGE: {
        name: '课程包',
        color: '',
    },
    // ARTICLE_PACKAGE: {
    //     name: '任务包',
    //     color: '',
    // },
}

export const productDetailType = [
    {
        name: '课程包',
        val: 'COURSE_PACKAGE',
    },
    // {
    //     name: '任务包',
    //     val: 'ARTICLE_PACKAGE',
    // },
]
