<template>
    <el-drawer :title="title" :visible.sync="isShow" size="560px" :before-close="onClose" @opened="onOpened">
        <el-form v-if="formData" ref="formData" :model="formData" :rules="rules" label-width="80px">
            <el-form-item label="项目" prop="projectNo">
                <ProjectSelect :defaultValue="formData.projectNo" @change="(val) => (formData.projectNo = val)" />
            </el-form-item>
            <!-- <el-form-item label="分类" prop="categoryId">
                <el-select v-model="formData.categoryId" filterable placeholder="请选择">
                    <el-option v-for="item in categoryList" :key="item.id" :label="item.name" :value="item.id" />
                </el-select>
            </el-form-item> -->
            <el-form-item label="标题" prop="title">
                <el-input placeholder="请输入" :maxlength="64" show-word-limit v-model.trim="formData.title" />
            </el-form-item>
            <el-form-item label="简介" prop="subtitle">
                <el-input
                    placeholder="请输入"
                    :autosize="{ minRows: 3, maxRows: 4 }"
                    :maxlength="128"
                    show-word-limit
                    v-model="formData.subtitle"
                />
            </el-form-item>
            <el-row>
                <el-col :span="10">
                    <el-form-item label="现价" prop="price">
                        <el-input-number :controls="false" :precision="2" :min="0" v-model="formData.price" />
                    </el-form-item>
                </el-col>
                <el-col :span="10">
                    <el-form-item label="原价" prop="originalPrice">
                        <el-input-number :controls="false" :precision="2" :min="0" v-model="formData.originalPrice" />
                    </el-form-item>
                </el-col>
            </el-row>

            <el-form-item label="封面图" prop="bannerUrl">
                <el-upload
                    class="upload_image"
                    action=""
                    accept="image/*"
                    :show-file-list="false"
                    :before-upload="onBannerUrlUpload"
                >
                    <i v-if="!formData.bannerUrl" class="el-icon-picture" />
                    <el-image v-else :src="formData.bannerUrl" fit="cover" />
                    <div slot="tip" class="el-upload__tip">推荐比例 16:9</div>
                </el-upload>
            </el-form-item>
            <el-form-item label="内容介绍" prop="descriptionUrl">
                <el-upload
                    class="upload_image"
                    action=""
                    accept="image/*"
                    :show-file-list="false"
                    :before-upload="onDescriptionUrlUpload"
                >
                    <i v-if="!formData.descriptionUrl" class="el-icon-picture" />
                    <el-image v-else :src="formData.descriptionUrl" fit="cover" />
                </el-upload>
            </el-form-item>

            <el-form-item label="上架状态">
                <el-radio-group v-model="formData.onSale">
                    <el-radio-button :label="true">上架</el-radio-button>
                    <el-radio-button :label="false">下架</el-radio-button>
                </el-radio-group>
            </el-form-item>
            <el-form-item>
                <div slot="label" style="font-size: 13px; line-height: 1em">允许学员多次购买</div>
                <el-radio-group v-model="formData.repeatFlag">
                    <el-radio-button :label="false">禁止</el-radio-button>
                    <el-radio-button :label="true">允许</el-radio-button>
                </el-radio-group>
            </el-form-item>
            <el-form-item label="收集信息">
                <el-checkbox-group v-model="formData.collectsList">
                    <el-checkbox label="name">姓名</el-checkbox>
                    <el-checkbox label="address">收货地址</el-checkbox>
                </el-checkbox-group>
            </el-form-item>

            <el-form-item label="备注" prop="remark">
                <el-input
                    placeholder="请输入"
                    type="textarea"
                    :autosize="{ minRows: 3, maxRows: 4 }"
                    :maxlength="128"
                    show-word-limit
                    v-model="formData.remark"
                />
            </el-form-item>

            <el-form-item>
                <el-button type="primary" @click="onSubmit">确定</el-button>
                <el-button @click="onClose">取消</el-button>
            </el-form-item>
        </el-form>
    </el-drawer>
</template>
<script>
import ProjectSelect from '@/views/components/ProjectSelect'
import { saveProduct } from '@/js/api/shopApi.js'
import { imageCompressor, UPLOAD_FILE } from '@/js/aliOss.js'
import { numToCent } from '@/js/numTools.js'

export default {
    components: { ProjectSelect },
    props: ['isShow', 'activeItem', 'categoryList', 'projectNo'],
    data() {
        const priceValidator = (rule, value, callback) => {
            if (value) {
                callback()
            } else {
                callback('请输入')
            }
        }
        return {
            title: '',
            formData: null,
            rules: {
                // categoryId: [{ required: true, message: '请选择分类', trigger: 'blur' }],
                title: [{ required: true, message: '请输入标题', trigger: 'blur' }],
                price: [{ required: true, validator: priceValidator, trigger: 'blur' }],
                originalPrice: [{ required: true, validator: priceValidator, trigger: 'blur' }],
                bannerUrl: [{ required: true, message: '请上传封面图', trigger: 'blur' }],
                descriptionUrl: [{ required: true, message: '请上传图片', trigger: 'blur' }],
            },
        }
    },
    methods: {
        onOpened() {
            this.title = '新建商品'
            let formData = {
                projectNo: this.projectNo,
                // categoryId: '',
                title: '',
                subtitle: '',
                price: 0,
                originalPrice: 0,
                onSale: true,
                bannerUrl: '',
                descriptionUrl: '',
                remark: '',
                repeatFlag: false,
                // name, address
                collects: '',
                collectsList: [],
            }

            if (this.activeItem) {
                this.title = `${this.activeItem.title} - 编辑`
                formData = { ...formData, ...this.activeItem }
                formData.price = formData.price / 100
                formData.originalPrice = formData.originalPrice / 100
                formData.collectsList = formData.collects ? formData.collects.split(',') : []
            }
            this.formData = formData
        },
        onClose() {
            this.title = ''
            this.formData = null
            this.$emit('onClose')
        },
        async onBannerUrlUpload(file) {
            const _file = await imageCompressor(file)
            UPLOAD_FILE({
                bucket: 'res',
                file: _file,
                path: 'resource/',
                success: (res) => {
                    this.formData.bannerUrl = res.url
                    this.$refs.formData.validateField(['bannerUrl'])
                },
            })
            return false
        },
        async onDescriptionUrlUpload(file) {
            const _file = await imageCompressor(file)
            UPLOAD_FILE({
                bucket: 'res',
                file: _file,
                path: 'resource/',
                success: (res) => {
                    this.formData.descriptionUrl = res.url
                    this.$refs.formData.validateField(['descriptionUrl'])
                },
            })
            return false
        },
        async onLiveSellImageUpload(file) {
            const _file = await imageCompressor(file)
            UPLOAD_FILE({
                bucket: 'res',
                file: _file,
                path: 'resource/',
                success: (res) => {
                    this.formData.liveSellImage = res.url
                    this.$refs.formData.validateField(['liveSellImage'])
                },
            })
            return false
        },
        onSubmit() {
            this.$refs.formData.validate((valid) => {
                if (valid) {
                    saveProduct({
                        ...this.formData,
                        price: numToCent(this.formData.price),
                        originalPrice: numToCent(this.formData.originalPrice),
                        collects: this.formData.collectsList.join(','),
                    }).then(() => {
                        this.onClose()
                        this.$emit('onClose', true)
                        this.$message.success('编辑成功')
                    })
                }
            })
        },
    },
}
</script>
<style lang="less" scoped>
.el-select {
    width: 100%;
}
</style>
