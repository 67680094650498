<template>
    <el-drawer title="商品分类管理" :visible.sync="isShow" size="720px" :before-close="onClose" @opened="onOpened">
        <div class="header">
            <el-button @click="onEditCategory(null)" type="primary">新建分类</el-button>
        </div>
        <el-table :data="tableData" border stripe height="calc(100% - 64px)">
            <el-table-column align="center" type="index" label="序号" width="72" />
            <el-table-column align="center" prop="categoryNo" label="编号" width="80" />
            <el-table-column header-align="center" prop="name" label="名称" />
            <el-table-column align="center" prop="addTime" label="创建时间" width="160" />

            <el-table-column align="center" label="操作" width="64">
                <template slot-scope="scope">
                    <el-link @click="onEditCategory(scope.row)" type="primary">编辑</el-link>
                    <el-popconfirm title="确定删除吗？" @confirm="onDelCategory(scope.row)">
                        <el-link slot="reference" type="danger">删除</el-link>
                    </el-popconfirm>
                </template>
            </el-table-column>
        </el-table>
    </el-drawer>
</template>
<script>
import { saveProductCategory, getProductCategoryList, delProductCategory } from '@/js/api/shopApi.js'
import { mapGetters } from 'vuex'

export default {
    props: ['isShow', 'activeItem'],
    computed: { ...mapGetters(['userInfo']) },
    data() {
        return {
            tableData: [],
        }
    },
    methods: {
        onOpened() {
            this.updateState = false
            this.getProductCategoryListApi()
        },
        onClose() {
            this.title = ''
            this.$emit('onClose', this.updateState)
        },

        onEditCategory(item) {
            this.$prompt('请输入分类名称', '分类设置', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                inputPattern: /\S/,
                inputValue: item ? item.name : '',
                inputErrorMessage: '请输入内容',
            })
                .then(({ value }) => {
                    const params = { name: value.trim() }
                    if (item) params.id = item.id
                    saveProductCategory(params).then(() => {
                        this.$message.success('编辑成功')
                        this.getProductCategoryListApi()
                        this.updateState = true
                    })
                })
                .catch(() => {})
        },
        onDelCategory(item) {
            delProductCategory({ id: item.id }).then(() => {
                this.updateState = true
                this.$message.success('删除成功')
                this.getProductCategoryListApi()
            })
        },

        getProductCategoryListApi() {
            getProductCategoryList({}).then((res) => {
                this.tableData = res.data
            })
        },
    },
}
</script>
<style lang="less" scoped>
.header {
    margin-bottom: 20px;
}
</style>
