<template>
    <el-drawer :title="title" :visible.sync="isShow" size="720px" :before-close="onClose" @opened="onOpened">
        <div>
            <el-button type="primary" @click="onShowEditMappingInfo()" plain>关联课程</el-button>
        </div>
        <br />
        <el-table :data="tableData" border>
            <el-table-column align="center" label="关联类型" width="100">
                <template slot-scope="scope">
                    <Tag :color="productDetailTypeMap[scope.row.type]['color']">
                        {{ productDetailTypeMap[scope.row.type]['name'] }}
                    </Tag>
                </template>
            </el-table-column>
            <el-table-column header-align="center" prop="content.title" label="标题">
                <template slot-scope="scope">
                    <Tag :color="packageTypeMap[scope.row.content.ptype]['color']">
                        {{ packageTypeMap[scope.row.content.ptype]['name'] }}
                    </Tag>
                    <span style="margin-left: 10px">{{ scope.row.content.title }}</span>
                </template>
            </el-table-column>
            <el-table-column label="操作" width="60">
                <template slot-scope="scope">
                    <el-popconfirm title="确定删除吗？" @confirm="onDelMapping(scope.row)">
                        <el-link slot="reference" type="danger">删除</el-link>
                    </el-popconfirm>
                </template>
            </el-table-column>
        </el-table>

        <EditMappingInfo
            :isShow.sync="isShowEditMappingInfo"
            :activeProduct="activeItem"
            @onClose="onCloseEditMappingInfo"
        />
    </el-drawer>
</template>
<script>
import EditMappingInfo from './EditMappingInfo.vue'
import { getProductDetailList, delProductDetail } from '@/js/api/shopApi.js'
import { productDetailTypeMap } from '@/js/config/shop.js'
import { packageTypeMap } from '@/js/config/course.js'

export default {
    props: ['isShow', 'activeItem'],
    components: { EditMappingInfo },
    data() {
        return {
            packageTypeMap,
            productDetailTypeMap,

            title: '',
            tableData: [],

            isShowEditMappingInfo: false,
        }
    },
    methods: {
        onOpened() {
            this.title = '商品详情'
            if (this.activeItem) {
                this.title = `${this.activeItem.title} - 详情`
            }
            this.getProductDetailListApi()
        },
        onClose() {
            this.title = ''
            this.$emit('onClose')
        },

        // 编辑关联课程
        onShowEditMappingInfo() {
            this.isShowEditMappingInfo = true
        },
        onCloseEditMappingInfo(status) {
            this.isShowEditMappingInfo = false
            if (status) this.getProductDetailListApi()
        },

        onDelMapping(item) {
            delProductDetail({ id: item.id }).then(() => {
                this.getProductDetailListApi()
            })
        },

        getProductDetailListApi() {
            const params = { productId: this.activeItem.id, orderBy: '-id', showPage: false }
            getProductDetailList(params).then((res) => {
                this.tableData = res.data
            })
        },
    },
}
</script>
<style lang="less" scoped></style>
