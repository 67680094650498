<template>
    <el-dialog :title="title" :visible.sync="isShow" width="480px" :before-close="onClose" @opened="onOpened">
        <el-form label-width="80px" v-if="activeItem">
            <el-form-item label="商品">
                <el-input disabled :value="activeItem.title" />
            </el-form-item>
            <el-form-item label="总价">
                <el-input disabled :value="activeItem.price / 100" />
            </el-form-item>
            <el-form-item label="待支付(元)">
                <el-input-number :precision="2" v-model="fee" :min="depositLimit / 100" :controls="false" />
                <span style="margin-left: 10px">
                    定金不可低于 <span style="color: red">{{ depositLimit / 100 }}</span> 元
                </span>
            </el-form-item>
            <el-form-item>
                <el-button type="primary" @click="onSubmit" :disabled="depositLimit >= activeItem.price">
                    {{
                        depositLimit >= activeItem.price
                            ? `仅支持商品价格 ${depositLimit / 100}以上创建定金`
                            : '立即创建'
                    }}
                </el-button>
            </el-form-item>
            <el-form-item label="支付地址" v-show="productUrl">
                <div class="product_url">
                    <el-input :value="productUrl" />
                    <el-link type="primary" @click="onCopyUrl"> <i class="el-icon-document-copy" /> </el-link>
                </div>
            </el-form-item>
        </el-form>
    </el-dialog>
</template>
<script>
import { getSystemConfig } from '@/js/api/commonApi.js'
import { getPayCode } from '@/js/api/userApi.js'
import { numToCent } from '@/js/numTools.js'
import { mapGetters } from 'vuex'

export default {
    props: ['isShow', 'activeItem'],
    computed: { ...mapGetters(['userInfo', 'BEGONIA_HOST']) },
    data() {
        return {
            title: '',

            fee: 0,
            productUrl: '',

            depositLimit: 0,
        }
    },

    methods: {
        onOpened() {
            this.title = `${this.activeItem.title} - 创建定金`

            this.getDepositConfig()
        },
        onClose() {
            this.fee = 0
            this.productUrl = ''
            this.$emit('onClose')
        },

        onCopyUrl() {
            window.navigator.clipboard.writeText(this.productUrl).then(() => {
                this.$message.success('复制成功')
            })
        },
        onSubmit() {
            if (this.fee === 0) {
                return this.$message.error('请输入价格')
            }
            this.productUrl = ''
            const { id, productNo } = this.activeItem
            getPayCode({ productId: id, fee: numToCent(this.fee) }).then((res) => {
                this.productUrl = `${this.BEGONIA_HOST}/product.html?productNo=${productNo}&payCode=${res.data}&sn=${this.userInfo.staffNo}`
            })
        },

        getDepositConfig() {
            getSystemConfig({ func: 'trade', key: 'deposit_limit' }).then((res) => {
                this.fee = res.data.deposit_limit / 100
                this.depositLimit = res.data.deposit_limit
            })
        },
    },
}
</script>
<style lang="less" scoped>
.product_url {
    display: flex;
    .el-input {
        flex-grow: 1;
    }
    .el-link {
        flex-shrink: 0;
        margin-left: 12px;
        font-size: 16px;
    }
}
</style>
