// 课程包 pType

// 公开课 OPEN_LIVE_ROUND
export const packageTypeList = [
    { name: '阶段课', val: 'PAID_VIDEO_STAGE' },
    { name: '直播课', val: 'PAID_LIVE_GIFT' },
    { name: '录播课', val: 'PAID_VIDEO_GIFT' },
]
export const packageTypeMap = {
    PAID_VIDEO_STAGE: { name: '阶段课', color: '#5649ea' },
    PAID_LIVE_GIFT: { name: '直播课', color: '#0ca822' },
    PAID_VIDEO_GIFT: { name: '录播课', color: '#f49117' },
}

export const courseProviderList = [
    { name: '欢拓直播', val: 'talkfun' },
    { name: '本地视频', val: 'video' },
]

export const courseProviderMap = {
    talkfun: { name: '欢拓直播', color: '#e15c5c' },
    video: { name: '本地视频', color: '#4f4f56' },
}
