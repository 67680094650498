<template>
    <div class="page_container">
        <div class="query_header">
            <div class="query_conditions">
                <div class="condition_item">
                    <span class="condition_label">项目</span>
                    <span class="condition_content">
                        <ProjectSelect @change="onProjectNoChange" />
                    </span>
                </div>
                <div class="condition_item">
                    <span class="condition_label">商品名称</span>
                    <span class="condition_content">
                        <el-input
                            v-model.trim="title"
                            placeholder="请输入"
                            clearable
                            @keyup.enter.native="onPaginationChange('page', 1)"
                        />
                    </span>
                </div>
                <!-- <div class="condition_item">
                    <span class="condition_label">商品分类</span>
                    <span class="condition_content">
                        <el-select v-model="categoryId" filterable placeholder="请选择" clearable>
                            <el-option
                                v-for="item in categoryList"
                                :label="item.name"
                                :value="item.id"
                                :key="item.id"
                            />
                        </el-select>
                    </span>
                </div> -->
                <div class="condition_item">
                    <span class="condition_label">上架状态</span>
                    <span class="condition_content">
                        <el-select v-model="saleFlag" filterable placeholder="请选择" clearable>
                            <el-option label="上架中" :value="true" />
                            <el-option label="已下架" :value="false" />
                        </el-select>
                    </span>
                </div>
            </div>
            <div class="opt_container">
                <el-button @click="onReset">重置</el-button>
                <el-button @click="onPaginationChange('page', 1)" type="primary">查询</el-button>

                <el-button
                    v-if="P(1040102)"
                    class="float_left_btn"
                    type="primary"
                    plain
                    @click="onShowEditProduct(null)"
                >
                    新建商品
                </el-button>
                <!-- <el-button v-if="P(1040101)" class="float_left_btn" plain @click="onShowCategoryDrawer(null)">
                    商品分类管理
                </el-button> -->
            </div>
        </div>
        <el-table :data="tableData" stripe border>
            <el-table-column align="center" type="index" label="序号" width="72" />
            <el-table-column align="center" prop="productNo" label="编号" width="80" />
            <el-table-column align="center" label="封面" width="160">
                <template slot-scope="scope">
                    <el-image
                        style="width: 120px"
                        fit="cover"
                        :src="scope.row.bannerUrl"
                        :preview-src-list="[scope.row.bannerUrl]"
                    />
                </template>
            </el-table-column>

            <el-table-column header-align="center" label="标题">
                <template slot-scope="scope">
                    <el-link :href="scope.row.previewUrl" target="_blank" type="primary">
                        {{ scope.row.title }}
                    </el-link>
                </template>
            </el-table-column>
            <el-table-column header-align="center" prop="subtitle" label="简介" />
            <el-table-column align="center" label="价格" width="80">
                <template slot-scope="scope">
                    <NumFormat :num="scope.row.price / 100" :float="2" />
                </template>
            </el-table-column>
            <el-table-column header-align="center" prop="remark" label="备注" min-width="100" />
            <el-table-column align="center" label="状态" width="100">
                <template slot-scope="scope">
                    <Tag v-if="scope.row.onSale" color="#52c504">上架中</Tag>
                    <Tag v-else color="#4f5154">已下架</Tag>
                </template>
            </el-table-column>
            <el-table-column align="center" label="上架状态" width="100">
                <template slot-scope="scope">
                    <el-switch
                        :disabled="!P(1040104)"
                        @change="onStateChange(scope.row)"
                        v-model="scope.row.switchState"
                    />
                </template>
            </el-table-column>

            <el-table-column align="center" label="操作" width="80">
                <template slot-scope="scope">
                    <div class="mini_link">
                        <el-link
                            type="primary"
                            v-if="P(1040102)"
                            @click="onHandleCommand({ ...scope.row, command: 'edit' })"
                        >
                            编辑商品
                        </el-link>
                        <el-link type="warning" @click="onShowEditDeposit(scope.row)">创建定金</el-link>
                        <el-popconfirm
                            title="确定删除吗？"
                            v-if="P(1040103)"
                            @confirm="onHandleCommand({ ...scope.row, command: 'delete' })"
                        >
                            <el-link slot="reference" type="danger">删除商品</el-link>
                        </el-popconfirm>
                    </div>
                </template>
            </el-table-column>
        </el-table>

        <el-pagination
            background
            :page-sizes="[10, 20, 50, 100]"
            @size-change="(val) => onPaginationChange('size', val)"
            @current-change="(val) => onPaginationChange('page', val)"
            :current-page.sync="page"
            :page-size="pageSize"
            layout="total, sizes, prev, pager, next, jumper"
            :total="total"
        />

        <EditProduct
            :projectNo="projectNo"
            :categoryList="categoryList"
            :isShow="isShowEditProduct"
            :activeItem="activeItem"
            @onClose="onCloseEditProduct"
        />
        <EditDeposit :isShow="isShowEditDeposit" :activeItem="activeItem" @onClose="onCloseEditDeposit" />
        <ProductDetail :isShow="isShowProductDetail" :activeItem="activeItem" @onClose="onCloseProductDetail" />
        <CategoryDrawer :isShow="isShowCategoryDrawer" @onClose="onCloseCategoryDrawer" />
    </div>
</template>

<script>
import EditProduct from './components/product/EditProduct.vue'
import EditDeposit from './components/product/EditDeposit.vue'
import ProductDetail from './components/product/ProductDetail.vue'
import CategoryDrawer from './components/product/CategoryDrawer.vue'
import ProjectSelect from '@/views/components/ProjectSelect'
import { getProductList, saveProduct, delProduct, getProductCategoryList } from '@/js/api/shopApi.js'
import { filterQueryEmpty } from '@/js/utils.js'
import { mapGetters } from 'vuex'

export default {
    computed: { ...mapGetters(['userInfo', 'BEGONIA_HOST']) },
    components: { CategoryDrawer, ProductDetail, EditProduct, EditDeposit, ProjectSelect },
    data() {
        return {
            projectNo: '',
            title: '',
            categoryList: [],
            categoryId: '',
            saleFlag: '',

            tableData: [],
            page: 1,
            pageSize: 10,
            total: 0,

            activeItem: null,
            isShowEditProduct: false,
            isShowCategoryDrawer: false,
            isShowProductDetail: false,
            isShowEditDeposit: false,
        }
    },
    created() {
        this.getProductCategoryListApi()
    },
    methods: {
        onReset() {
            this.title = ''
            this.categoryId = ''
            this.saleFlag = ''
            this.onPaginationChange('page', 1)
        },
        onProjectNoChange(val) {
            this.projectNo = val
            this.onReset()
        },

        onPaginationChange(type, value) {
            if (type === 'size') {
                this.pageSize = value
                this.page = 1
            } else {
                this.page = value
            }
            this.$nextTick(this.getProductListApi)
        },

        onStateChange(item) {
            saveProduct({ ...item, onSale: item.switchState }).then(() => {
                this.$message.success('编辑成功')
                this.getProductListApi()
            })
        },

        // 编辑商品
        onShowEditProduct(item) {
            this.activeItem = item
            this.isShowEditProduct = true
        },
        onCloseEditProduct(state) {
            this.activeItem = null
            this.isShowEditProduct = false
            if (state) this.getProductListApi()
        },
        // 商品详情
        onShowProductDetail(item) {
            this.activeItem = item
            this.isShowProductDetail = true
        },
        onCloseProductDetail() {
            this.activeItem = null
            this.isShowProductDetail = false
        },
        // 创建定金
        onShowEditDeposit(item) {
            this.activeItem = item
            this.isShowEditDeposit = true
        },
        onCloseEditDeposit() {
            this.activeItem = null
            this.isShowEditDeposit = false
        },

        onHandleCommand(item) {
            if (item.command === 'edit') {
                this.onShowEditProduct(item)
            } else if (item.command === 'delete') {
                this.$confirm(item.title, '删除以下商品吗？', {
                    confirmButtonText: '确定',
                    cancelButtonText: '取消',
                    type: 'error',
                })
                    .then(() => {
                        delProduct({ id: item.id }).then(() => {
                            this.$message.success('删除成功')
                            this.getProductListApi()
                        })
                    })
                    .catch(() => {})
            }
        },

        getProductListApi() {
            let params = {
                page: this.page,
                size: this.pageSize,
                showPage: true,
                saleFlag: this.saleFlag,
                title: this.title,
                orderBy: '-id',
                projectNo: this.projectNo,
            }
            if (this.categoryId) params.categoryIds = [this.categoryId]

            params = filterQueryEmpty(params)
            getProductList(params).then((res) => {
                res.data.list.forEach((item) => {
                    item.switchState = item.onSale
                    item.previewUrl = `${this.BEGONIA_HOST}/product.html?productNo=${item.productNo}&sn=${this.userInfo.staffNo}`
                })
                this.tableData = res.data.list
                this.total = res.data.total
                this.page = res.data.pageNum
            })
        },

        // 分类管理
        onShowCategoryDrawer() {
            this.isShowCategoryDrawer = true
        },
        onCloseCategoryDrawer(state) {
            this.isShowCategoryDrawer = false
            if (state) this.getProductCategoryListApi()
        },
        getProductCategoryListApi() {
            getProductCategoryList({}).then((res) => {
                this.categoryList = res.data
            })
        },
    },
}
</script>
