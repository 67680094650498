// 百分比
export const getPercentage = (num, precision) => {
    if (!num || isNaN(num)) return 0
    const m = [0, 1, 2].includes(precision) ? precision : 2
    const n = Math.pow(10, m)
    const a = Math.round(num * 100 * n) / n
    return a.toFixed(m)
}

// precision: 0 - 2
export const getEllipsisNum = (num, precision) => {
    if (!num || isNaN(num)) return 0
    const m = precision || precision === 0 ? precision : 2
    const n = Math.pow(10, 4 - m)
    const j = Math.pow(10, m)
    if (num < 10000) {
        return num.toFixed(m)
    }
    return Math.round(num / n) / j + '万'
}

export const numFormat = function (num, float) {
    if (!num || isNaN(num)) return 0

    // float 最大为 text 长度
    let text = '0000000000'
    const f = float || float === 0 ? float : 2
    let n = typeof num == 'string' ? parseFloat(num) : num
    n = n.toFixed(f)
    n = parseFloat(n)
    n = n.toLocaleString()
    if (n.indexOf('.') === -1 && f) {
        n = n + '.' + text.slice(0, f)
    } else if (n.indexOf('.') !== -1 && n.split('.')[1].length === 1) {
        const len = n.split('.')[1].length
        n = n + text.slice(0, f - len)
    }
    return n
}

// 数字 转 分
export const numToCent = function (num) {
    return Math.round(num * 100)
}

export const numToText = function (num) {
    const n = `${num}`
    if (n.indexOf('.') !== -1) {
        const m = n.split('.')[0].replace(/\d{1,3}(?=(\d{3})+$)/g, (s) => `${s},`)
        return `${m}.${n.split('.')[1]}`
    } else {
        return n.replace(/\d{1,3}(?=(\d{3})+$)/g, (s) => `${s},`)
    }
}
